@font-face {
  font-family: 'Gotham';
  font-style: normal;
  src: local('Gotham Book'), local('Gotham-Book'),
    url(./Gotham-Book.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Medium';
  font-style: normal;
  src: local('Gotham Medium'), local('Gotham-Medium'),
    url(./Gotham-Medium.otf) format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  src: local('Gotham Bold'), local('Gotham-Bold'),
    url(./Gotham-Bold.otf) format('opentype');
  font-display: swap;
}
